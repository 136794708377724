body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #f5fafd;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
	text-decoration: none;
}

.MuiFilledInput-input {
	padding: 11px 14px !important;
}
@font-face {
	font-family: 'Work Sans';
	src: url('./assets/fonts/WorkSans-VariableFont_wght.ttf') format('truetype');
	font-style: normal;
}

@font-face {
	font-family: 'Work Sans';
	src: url('./assets/fonts/WorkSans-Italic-VariableFont_wght.ttf') format('ttf');
	font-style: italic;
}
